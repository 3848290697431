// // If you want to override variables do it here
@import './variables.scss';

@layer reboot, primeng;
// // Import styles

// EITHER ALL @import '@coreui/coreui/scss/coreui';
// // OR :((
// @import '@coreui/coreui/scss/_functions';
// @import '@coreui/coreui/scss/_variables';
// @import '@coreui/coreui/scss/_mixins';
// @import '@coreui/coreui/scss/_root';
// @import '@coreui/coreui/scss/_reboot';
// button:focus {
//     outline: unset;
// }

// // Vendors
// @import '@coreui/coreui/scss/_vendors';

// // Components
// @import '@coreui/coreui/scss/_alert';
// @import '@coreui/coreui/scss/_avatars';
// @import '@coreui/coreui/scss/_badge';
// @import '@coreui/coreui/scss/_breadcrumb-menu';
// @import '@coreui/coreui/scss/_breadcrumb';
// @import '@coreui/coreui/scss/_button-group';
// @import '@coreui/coreui/scss/_buttons';

// //Override icons
// .btn i {
//     height: unset;
//     width: unset;
//     margin: unset;
// }
// // end override
// @import '@coreui/coreui/scss/_callout';
// @import '@coreui/coreui/scss/_card';
// // @import "carousel";
// // @import "charts";
// @import '@coreui/coreui/scss/_close';
// // @import "code";
// // @import "custom-forms";
// @import '@coreui/coreui/scss/_dropdown';
// @import '@coreui/coreui/scss/_footer';
// @import '@coreui/coreui/scss/_forms';
// @import '@coreui/coreui/scss/_grid';
// @import '@coreui/coreui/scss/_header';
// // @import "icon";
// @import '@coreui/coreui/scss/_input-group';
// @import '@coreui/coreui/scss/_images';
// // @import "jumbotron";
// // @import "list-group";
// // @import "media";
// @import '@coreui/coreui/scss/_modal';
// @import '@coreui/coreui/scss/_nav';
// @import '@coreui/coreui/scss/_navbar';
// @import '@coreui/coreui/scss/_pagination';
// @import '@coreui/coreui/scss/_popover';
// @import '@coreui/coreui/scss/_progress';
// // @import "progress-group";
// @import '@coreui/coreui/scss/_sidebar';
// @import '@coreui/coreui/scss/_spinners';
// @import '@coreui/coreui/scss/_switches';
// @import '@coreui/coreui/scss/_tables';
// // @import "toasts";
// @import '@coreui/coreui/scss/_tooltip';
// @import '@coreui/coreui/scss/_transitions';
// @import '@coreui/coreui/scss/_type';
// @import '@coreui/coreui/scss/_widgets';

// // Layout Options
// @import '@coreui/coreui/scss/_layouts';

// // Utility classes
// @import '@coreui/coreui/scss/_utilities';

// // Right-to-left
// // @import "rtl";

// // Custom Properties support for Internet Explorer
// @import '@coreui/coreui/scss/_ie-custom-properties';

// @import '@coreui/coreui/scss/_print';

// @import '@coreui/coreui/scss/coreui';

@import '@coreui/coreui/scss/_functions';
// $primary: #065bee !default;
// $primary-base: #065bee !default;
@import '@coreui/coreui/scss/_variables';

@import '@coreui/coreui/scss/_mixins';
@import '@coreui/coreui/scss/_root';
@import '@coreui/coreui/scss/_app';
// @import '@coreui/coreui/scss/_reboot';

// Vendors
@import '@coreui/coreui/scss/_vendors';

// Components
@import '@coreui/coreui/scss/_alert';
@import '@coreui/coreui/scss/_avatars';
@import '@coreui/coreui/scss/_badge';
@import '@coreui/coreui/scss/_breadcrumb-menu';
@import '@coreui/coreui/scss/_breadcrumb';
@import '@coreui/coreui/scss/_button-group';
@import '@coreui/coreui/scss/_buttons';
@import '@coreui/coreui/scss/_callout';
@import '@coreui/coreui/scss/_card';
@import '@coreui/coreui/scss/_carousel';
@import '@coreui/coreui/scss/_charts';
@import '@coreui/coreui/scss/_close';
@import '@coreui/coreui/scss/_code';
@import '@coreui/coreui/scss/_custom-forms';
@import '@coreui/coreui/scss/_dropdown';
@import '@coreui/coreui/scss/_footer';
@import '@coreui/coreui/scss/_forms';
@import '@coreui/coreui/scss/_grid';
@import '@coreui/coreui/scss/_header';
@import '@coreui/coreui/scss/_icon';
@import '@coreui/coreui/scss/_input-group';
@import '@coreui/coreui/scss/_images';
@import '@coreui/coreui/scss/_jumbotron';
@import '@coreui/coreui/scss/_list-group';
@import '@coreui/coreui/scss/_media';
@import '@coreui/coreui/scss/_modal';
@import '@coreui/coreui/scss/_nav';
@import '@coreui/coreui/scss/_navbar';
@import '@coreui/coreui/scss/_pagination';
@import '@coreui/coreui/scss/_popover';
@import '@coreui/coreui/scss/_progress';
@import '@coreui/coreui/scss/_progress-group';
@import '@coreui/coreui/scss/_sidebar';
@import '@coreui/coreui/scss/_spinners';
@import '@coreui/coreui/scss/_subheader';
@import '@coreui/coreui/scss/_switches';
@import '@coreui/coreui/scss/_tables';
@import '@coreui/coreui/scss/_toasts';
@import '@coreui/coreui/scss/_tooltip';
@import '@coreui/coreui/scss/_transitions';
@import '@coreui/coreui/scss/_type';
@import '@coreui/coreui/scss/_widgets';

// Layout Options
@import '@coreui/coreui/scss/_layouts';

// Utility classes
//we ignore flex...
// @import "@coreui/coreui/scss/_utilities";
@import '@coreui/coreui/scss/utilities/align';
@import '@coreui/coreui/scss/utilities/background';
// @import "@coreui/coreui/scss/utilities/borders";
@import '@coreui/coreui/scss/utilities/center';
@import '@coreui/coreui/scss/utilities/clearfix';
@import '@coreui/coreui/scss/utilities/display';
@import '@coreui/coreui/scss/utilities/embed';
// @import "@coreui/coreui/scss/utilities/flex";
@import '@coreui/coreui/scss/utilities/float';
@import '@coreui/coreui/scss/utilities/interactions';
@import '@coreui/coreui/scss/utilities/overflow';
@import '@coreui/coreui/scss/utilities/position';
@import '@coreui/coreui/scss/utilities/screenreaders';
@import '@coreui/coreui/scss/utilities/shadows';
@import '@coreui/coreui/scss/utilities/sizing';
// @import "@coreui/coreui/scss/utilities/spacing";
@import '@coreui/coreui/scss/utilities/stretched-link';
@import '@coreui/coreui/scss/utilities/text';
@import '@coreui/coreui/scss/utilities/typography';
@import '@coreui/coreui/scss/utilities/visibility';

// Custom Properties support for Internet Explorer
// Right-to-left
@import '@coreui/coreui/scss/_ie-custom-properties';

@import '@coreui/coreui/scss/_print';

@layer reboot {
    @import 'reboot';
}

label {
    padding-bottom: unset;
    margin-bottom: unset;
}

.btn i {
    height: unset;
    width: unset;
    margin: unset;
}
button:focus {
    outline: unset;
}

.form-control {
    color: unset;
}

.form-control:focus {
    color: unset;
}

//Header reduce Z index
.c-header.c-header-fixed {
    z-index: 10;
}

.table thead th {
    border-bottom-width: 1px;
}
// END CORE UI

//link angular validation to bootstrap
.ng-touched.ng-invalid {
    @extend .is-invalid;
}
div.ng-touched.ng-invalid > .form-control {
    @extend .is-invalid;
}

.warning-feedback {
    @extend .invalid-feedback;
    color: #ee6806;
}

@import '@fortawesome/fontawesome-free/css/all.css';
// If you want to add something do it here
// @import 'bootstrap/scss/bootstrap';
// ng select
@import '@ng-select/ng-select/themes/default.theme.css';

@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.css';

@import 'primeng/resources/primeng.min.css';
@import 'primeng/resources/themes/saga-blue/theme.css';
@import './primeng-override.scss';

@import './custom.scss';
@import './print.scss';

.login-modal {
    width: 500px;
}

@media (max-width: 600px) {
    .login-modal {
        width: 90vw;
    }
}

.toast-full-screen {
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

.toast-container .ngx-toastr.toast-full-screen-message {
    width: 50vw;
    padding-top: 3rem;
    padding-bottom: 3rem;
}
@media (max-width: 600px) {
    .toast-container .ngx-toastr.toast-full-screen-message {
        width: 100vw;
    }
}

.shake-rotate {
    animation: myAnim 2s ease-in-out 0s infinite normal forwards;
}

@keyframes myAnim {
    0%,
    100% {
        transform: rotate(0deg);
        transform-origin: 20% 80%;
    }

    10% {
        transform: rotate(4deg);
    }

    20%,
    40%,
    60% {
        transform: rotate(-8deg);
    }

    30%,
    50%,
    70% {
        transform: rotate(8deg);
    }

    80% {
        transform: rotate(-4deg);
    }

    90% {
        transform: rotate(4deg);
    }
}
.ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
    75%,
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    30% {
        transform: scale(0.5);
    }

    70% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes scale {
    100% {
        transform: scale(0.5);
    }
}
@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.hithere {
    animation: hithere 1s ease infinite;
}
@keyframes hithere {
    30% {
        transform: scale(1.2);
    }
    40%,
    60% {
        transform: rotate(-20deg) scale(1.2);
    }
    50% {
        transform: rotate(20deg) scale(1.2);
    }
    70% {
        transform: rotate(0deg) scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.bounce2 {
    animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.feedback-button {
    border: 1px solid white;
    background: none;
    color: #dee2e6;
    animation: shake 1s ease 3;
    &:hover {
        background: #dee2e6;
        color: #495057;
    }
}
