// regular style toast
@import 'ngx-toastr/toastr';
#toast-container > div {
    opacity: 1;
}
// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions
//@import '~ngx-toastr/toastr-bs4-alert';

// Here you can add other styles
.material-icons.md-18 {
    font-size: 18px;
}
.material-icons.md-24 {
    font-size: 24px;
}
.material-icons.md-36 {
    font-size: 36px;
}
.material-icons.md-48 {
    font-size: 48px;
}

i.mi {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
.ng-select.ng-select-single .ng-select-container {
    height: 39px;
}

ng-select.form-control {
    border: unset;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
    border-color: var(--danger);
    // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.print-only {
    display: none;
    // border: 2px dashed red;
}
// .print-invisible {
//     display: revert;
// }

// @media print {
//     .print-invisible {
//         // display: none !important;
//         border: 2px solid red;
//     }
//     .print-only {
//         display: block;
//     }
// }

body > ng-component {
    width: 100%;
}

ui-button {
    margin: 0 2px;
}

.btn:first-of-type,
ui-button:first-of-type {
    margin-left: 0;
}

//BUttony v primeng su rozbite kvoli _cards z coreui:

.p-button-icon-only {
    overflow-wrap: initial;
}
// .btn:last-of-type,
// ui-button:last-of-type {
//     margin-right: 0;
// }

.fc-toolbar-chunk {
    display: flex;
}

table.table {
    margin-bottom: 0px;
}

table.table > tbody > tr > td {
    vertical-align: middle;
}

@media print {
    .card {
        overflow: hidden;
    }
    .bg-primary {
        background-color: none !important;
    }
    .text-white {
        color: #000 !important;
    }
    .card {
        border-color: #000 !important;
    }

    tr {
        border-top: 1px solid #000 !important;
    }
}
