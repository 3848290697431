/* You can add global styles to this file, and also import other style files */
.c-elevation-2 {
    border: 0;
    box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14), 0 3px 1px -2px rgba(60, 75, 100, 0.12),
        0 1px 5px 0 rgba(60, 75, 100, 0.2);
}

.fade-in {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

.float {
    position: fixed;
    bottom: 40px;
    right: 40px;
    box-shadow: 2px 2px 3px #999;
    z-index: 999;
    background-color: #fff;
}
.float.minimized {
    bottom: 50%;
    right: 0px;
    .feedback-text {
        display: none;
    }
    .p-button .p-button-icon-left {
        margin-right: 0px;
    }
}
