.p-calendar .p-inputtext {
    padding: 0.5rem;
    font-size: inherit;
}

.p-datepicker-trigger {
    line-height: initial;
}
.p-calendar {
    display: flex !important;
}

.app .p-datepicker table td {
    padding: 0.2em;
}

.app .p-datepicker .p-datepicker-buttonbar {
    padding: 0.2rem 0;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: #321fdb;
}
// decrease the padding
.p-dialog .p-dialog-footer {
    padding: 0 0.5rem 0.5rem 0.5rem;
}
.p-dialog .p-dialog-content {
    padding: 1rem;
}
.p-dialog .p-dialog-header {
    padding: 1rem;
}
//make small buttons smaller
.p-button.p-button-sm {
    padding: 0.25rem 0.5rem;
}
.p-button {
    align-items: baseline;
}

.p-button-icon-only {
    align-items: center;
}

.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th {
    padding: 0.75rem 0.75rem;
}

p-calendar.ng-invalid.ng-touched .p-inputtext {
    border: 1px solid var(--danger);
}

.p-togglebutton.p-button {
    padding: 0.25rem 0.5rem;
}
